import React from 'react';
import 'ol/ol.css';
import MapOL from 'ol/Map';
import View from 'ol/View';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {Draw, Modify, Select, Snap, DoubleClickZoom} from 'ol/interaction';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Control, defaults as defaultControls, MousePosition, ScaleLine} from "ol/control";
import {applyStyle} from 'ol-mapbox-style';
import VectorTileLayer from 'ol/layer/VectorTile.js';
import {Box} from "@mui/material";
import './Map.css';
import {Feature} from "ol";
import {fromLonLat} from "ol/proj";
import {MultiPoint, Polygon} from "ol/geom";

class RotateNorthControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');
        button.innerHTML = 'N';

        const element = document.createElement('div');
        element.className = 'rotate-north ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        button.addEventListener('click', this.handleRotateNorth.bind(this), false);
    }

    handleRotateNorth() {
        this.getMap().getView().setRotation(0);
    }
}

let scaleType = 'scaleline';
let scaleBarSteps = 4;
let scaleBarText = true;
let control;

function scaleControl() {
    control = new ScaleLine({
        units: 'metric',
        bar: true,
        steps: scaleBarSteps,
        text: scaleBarText,
        minWidth: 140,
    });
    return control;
}

export default class Map extends React.Component {
    constructor(props) {
        super(props);
        this.mapContainerRef = React.createRef();
        this.mapRef = React.createRef();
        this.state = {
            lat: 0,
            lng: 0,
            zoom: 0
        }

        this.clearSelection = () => {
            this.vector.getSource().clear();
            this.ed.setActive('select');
        }

        this.drawActivate = (type) => {
            this.ed.setActive('draw' + type);
        }
        this.modifyActivate = () => {
            this.ed.setActive('modify');
        }
        this.selectActivate = () => {
            this.ed.setActive('select');
        }

        this.toggleSnap = (value) => {
            this.snap.setActive(value);
        }
    }

    initDraw = (map, vector) => {
        class ExampleDraw {
            init = () => {
                map.addInteraction(this.Point);
                this.Point.setActive(false);
                map.addInteraction(this.LineString);
                this.LineString.setActive(false);
                map.addInteraction(this.Polygon);
                this.Polygon.setActive(false);
                this.Polygon.on('drawend', this.onDrawCompleted);
                map.addInteraction(this.Circle);
                this.Circle.setActive(false);
                map.addInteraction(this.Select);
                this.Select.setActive(false);
                this.Select.on('select', this.onSelect);
                map.addInteraction(this.Modify);
                this.Modify.setActive(false);
            }
            Point = new Draw({
                source: vector.getSource(),
                type: 'Point',
                stopClick: true,
            })
            LineString = new Draw({
                source: vector.getSource(),
                type: 'LineString',
                stopClick: true,
            })
            Polygon = new Draw({
                source: vector.getSource(),
                type: 'Polygon',
                stopClick: true,
            })
            Circle = new Draw({
                source: vector.getSource(),
                type: 'Circle',
                stopClick: true,
            })
            Select = new Select({
                style: false
            })
            Modify = new Modify({
                source: vector.getSource()
            })
            activeDraw = null
            setActive = (active) => {
                if (this.activeDraw) {
                    this.activeDraw.setActive(false);
                    this.activeDraw = null;
                }
                map.changed();
                map.getLayers().forEach((l)=>{l.changed();});
                if (active === 'select') {
                    this.activeDraw = this.Select;
                    this.activeDraw.setActive(true);
                }
                if (active === 'drawpolygon') {
                    this.activeDraw = this.Polygon;
                    this.activeDraw.setActive(true);
                }
                if (active === 'drawline') {
                    this.activeDraw = this.LineString;
                    this.activeDraw.setActive(true);
                }
                if (active === 'modify') {
                    this.activeDraw = this.Modify;
                    this.activeDraw.setActive(true);
                }
            }
            onDrawCompleted = (evt) => {
                this.controlDoubleClickZoom(false);
                setTimeout(() => {
                    this.controlDoubleClickZoom(true);
                }, 251);
            }
            controlDoubleClickZoom = (active) => {
                const interactions = map.getInteractions();
                for (let i = 0; i < interactions.getLength(); i++) {
                    const interaction = interactions.item(i);
                    if (interaction instanceof DoubleClickZoom) {
                        interaction.setActive(active);
                    }
                }
            }
            onSelect = (evt) => {
                const feature = evt.selected[0];
                vector.getSource().addFeature(feature);
            }
        }

        const ed = new ExampleDraw();
        ed.init();
        return ed;
    }

    componentDidMount() {
        this.vector = new VectorLayer({
            source: new VectorSource(),
            style: [
                new Style({
                    fill: new Fill({
                        color: 'rgba(54,255,0,0.63)',
                    }),
                    stroke: new Stroke({
                        color: '#3355ff',
                        width: 2,
                    }),
                    image: new CircleStyle({
                        radius: 7,
                        fill: new Fill({
                            color: '#ff3358',
                        }),
                    }),
                }),
                new Style({
                    image: new CircleStyle({
                        radius: 3,
                        fill: new Fill({
                            color: 'orange'
                        })
                    }),
                    geometry: function(feature) {
                        let coordinates = [];
                        if (feature.getGeometry() instanceof Polygon) {
                            feature.getGeometry().getCoordinates().forEach((x)=>{
                                coordinates = [...coordinates, ...x];
                            });
                        } else {
                            coordinates = feature.getGeometry().getCoordinates();
                        }
                        return new MultiPoint(coordinates);
                    }
                })
            ]
        });

        const vOSM = new VectorTileLayer({renderMode: 'vector'});
        applyStyle(vOSM, 'https://s57.ivazh.ru/styles/s57/style.json');

        const map = new MapOL({
            controls: defaultControls().extend([new RotateNorthControl(), scaleControl(), new MousePosition()]),
            target: this.mapContainerRef.current,
            layers: [
                vOSM,
                this.vector
            ],
            view: new View({
                center: [-8905619, 2923790],
                zoom: 10,
                rotation: 0
            })
        });

        this.mapRef.current = map;

        this.ed = this.initDraw(map, this.vector);
        this.ed.setActive('select');

        this.snap = new Snap({
            source: this.vector.getSource(),
        });
        map.addInteraction(this.snap);
    }

    render() {
        return (
            <Box sx={{width: '100%', height: '100%'}} ref={this.mapContainerRef} className="map-container"/>
        );
    }
}
