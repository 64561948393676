import './App.css';
import {AppBar, Box, Grid, ToggleButton, Toolbar} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import NorthWestIcon from '@mui/icons-material/NorthWest';
import DrawIcon from '@mui/icons-material/Brush';
import ModifyIcon from '@mui/icons-material/EditRoad';
import SnapIcon from '@mui/icons-material/GpsFixed';
import Map from "./Map";
import React from 'react';

class App extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            snap: true,
            mode: 'select'
        }

        this.mapRef = React.createRef();

        this.clearSelection = (evt,value) => {
            this.mapRef.current.clearSelection();
        }

        this.drawPolygonActivate = (evt, value) => {
            this.setState({mode: 'drawPolygon'});
            this.mapRef.current.drawActivate('polygon');
        }

        this.drawLineActivate = (evt, value) => {
            this.setState({mode: 'drawLine'});
            this.mapRef.current.drawActivate('line');
        }

        this.modifyActivate = (evt, value) => {
            this.setState({mode: 'modify'});
            this.mapRef.current.modifyActivate();
        }

        this.selectActivate = (evt, value) => {
            this.setState({mode: 'select'});
            this.mapRef.current.selectActivate();
        }

        this.snapToggle = (evt, value) => {
            const snap = !this.state.snap;
            this.setState({snap: snap});
            this.mapRef.current.toggleSnap(snap);
        }
    }

    render() {
        return (
            <Grid container sx={{width: '100vw', height: '100vh'}}>
                <Grid item xs>
                    <AppBar position={"static"} color={'default'}>
                        <Toolbar variant={"regular"}>
                            <ToggleButton
                                value={"check"}
                                size="small"
                                sx={{mr: 1}}
                                selected={this.state.mode === 'select'}
                                onChange={this.selectActivate}
                                title={'Выбрать'}
                            >
                                <NorthWestIcon/>
                            </ToggleButton>

                            <ToggleButton
                                value={"check"}
                                size="small"
                                sx={{mr: 1}}
                                selected={this.state.mode === 'drawPolygon'}
                                onChange={this.drawPolygonActivate}
                                title={'Нарисовать полигон'}
                            >
                                <DrawIcon/>
                            </ToggleButton>

                            <ToggleButton
                                value={"check"}
                                size="small"
                                sx={{mr: 1}}
                                selected={this.state.mode === 'drawLine'}
                                onChange={this.drawLineActivate}
                                title={'Нарисовать линию'}
                            >
                                <DrawIcon/>
                            </ToggleButton>

                            <ToggleButton
                                value={"check"}
                                size="small"
                                sx={{mr: 1}}
                                selected={this.state.mode === 'modify'}
                                onChange={this.modifyActivate}
                                title={'Редактировать'}
                            >
                                <ModifyIcon/>
                            </ToggleButton>

                            <ToggleButton
                                value={"check"}
                                size="small"
                                sx={{mr: 1}}
                                selected={this.state.snap}
                                onChange={this.snapToggle}
                                title={'Привязка'}
                            >
                                <SnapIcon/>
                            </ToggleButton>

                            <ToggleButton
                                value={"check"}
                                size="small"
                                sx={{mr: 1}}
                                onChange={this.clearSelection}
                                title={'Очистить'}
                            >
                                <ClearIcon/>
                            </ToggleButton>

                        </Toolbar>
                    </AppBar>
                    <Box sx={{width: '100%', height: 'calc(100% - 64px)'}}>
                        <Map ref={this.mapRef}/>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

export default App;
